<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Edit Produk</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/paket">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="updateData(paket)"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Judul</b></label>
            <b-form-input
              id="title"
              v-model="paket.title"
              placeholder="Judul"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Deskripsi"
              v-model="paket.description"
              rows="2"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Harga Produk</b></label>
            <b-form-input
              id="title"
              v-model="paket.price"
              placeholder="Harga"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <b-img
              center
              class="mb-1 mb-sm-0"
              height="120"
              :src="getUrl + 'penyimpanan/paket/' + paket.photo"
            />
            <label for="textarea-default"><b>Upload Photo (1980x660)</b></label>
            <b-form-group>
              <b-form-file
                v-if="uploadReady"
                ref="file"
                type="file"
                class="form-control"
                name="file"
                v-on:change="fileUpload($event.target)"
              />
            </b-form-group>
            <b-progress
              v-if="isLoading"
              animated
              :value="progressBar"
              :style="{ width: progressBar + '%' }"
              show-value
              :class="'progress-bar-info'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Link Produk</b></label>
            <b-form-input
              id="title"
              v-model="paket.link"
              placeholder="Judul"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 1</b></label>
            <b-form-input id="list1" v-model="paket.list_1" placeholder="List 1" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 2</b></label>
            <b-form-input id="list2" v-model="paket.list_2" placeholder="List 2" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 3</b></label>
            <b-form-input id="list3" v-model="paket.list_3" placeholder="List 3" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 4</b></label>
            <b-form-input id="list4" v-model="paket.list_4" placeholder="List 4" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 5</b></label>
            <b-form-input id="list5" v-model="paket.list_5" placeholder="List 5" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 6</b></label>
            <b-form-input id="list6" v-model="paket.list_6" placeholder="List 6" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 7</b></label>
            <b-form-input id="list7" v-model="paket.list_7" placeholder="List 7" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 8</b></label>
            <b-form-input id="list8" v-model="paket.list_8" placeholder="List 8" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 9</b></label>
            <b-form-input id="list9" v-model="paket.list_9" placeholder="List 9" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>List 10</b></label>
            <b-form-input id="list10" v-model="paket.list_10" placeholder="List 10" />
          </b-col>
        </b-row>

        <b-col md="6" xl="1" class="mb-1">
          <b-form-input
            hidden
            id="basicInput"
            v-model="paket.photo"
            placeholder="Name File"
          />
        </b-col>
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="CheckCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BFormFile,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BFormInput,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BFormInput,
    quillEditor,
    BForm,
    BFormFile,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      paket: [],
      id: this.$route.params.id,
      title: "",
      description: "",
      content: "",
      photo: "",
      file: "",
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      link: "",
      price: "",
      hidden_file: "",
      getUrl: "",
      list_1: "",
      list_2: "",
      list_3: "",
      list_4: "",
      list_5: "",
      list_6: "",
      list_7: "",
      list_8: "",
      list_9: "",
      list_10: "",
    };
  },
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `pakets/search/${this.$route.params.id}`
      )
      .then((response) => {
        this.paket = response.data.data;
        // console.log("cek test", this.paket);
      });
  },
    async mounted() {
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async updateData(paket) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("title", (this.title = paket.title));
      formData.append("description", (this.description = paket.description));
      formData.append("content", (this.content = paket.content));
      formData.append("hidden_file", (this.photo = paket.photo));
      formData.append("link", (this.link = paket.link));
      formData.append("price", (this.price = paket.price));

      formData.append("list1", (this.list_1 = paket.list_1));
      formData.append("list2", (this.list_2 = paket.list_2));
      formData.append("list3", (this.list_3 = paket.list_3));
      formData.append("list4", (this.list_4 = paket.list_4));
      formData.append("list5", (this.list_5 = paket.list_5));
      formData.append("list6", (this.list_6 = paket.list_6));
      formData.append("list7", (this.list_7 = paket.list_7));
      formData.append("list8", (this.list_8 = paket.list_8));
      formData.append("list9", (this.list_9 = paket.list_9));
      formData.append("list10", (this.list_10 = paket.list_10));
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `pakets/update/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.file = "";
                this.title = "";
                this.description = "";
                this.price = "";
                this.link = "";
                this.photo = "";
                this.isEdit = "Tidak";
                this.hidden_file = null;
                
                this.list1= "";
                this.list2= "";
                this.list3= "";
                this.list4= "";
                this.list5= "";
                this.list6= "";
                this.list7= "";
                this.list8= "";
                this.list9= "";
                this.list10= "";
                this.$router.push({ name: "list_paket" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                setTimeout(() => {
                  this.photoReset();
                  this.isLoading = false;
                  this.progressBar = 0;
                }, 2000);
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "CheckCircleIcon",
            text: "Batal Ubah Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_paket" });
      setTimeout(() => {
        this.photoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    fileUpload(event) {
      this.file = event.files[0];
    },
    photoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
  },
};
</script>
